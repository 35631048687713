import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ERoutes } from './static/data/ERoutes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Import Swiper styles
import 'swiper/css';

/* Tailwind & Ant-d CSS imports */
import './ui/theme/tailwind-setup.css';

/* Theme variables */
import './ui/theme/ionic-variables.css';
import './ui/theme/variables.css';
import './ui/theme/global.css';

/* _i18n */
import './_i18n/config';

import { Redirect } from 'react-router';

/** Pages imports */
import LandingPage from './ui/pages/LandingPage';
import { useVotingStateStore } from './store/voting-state-store';
import TestPage from './ui/pages/TestPage';

setupIonicReact({ mode: 'ios' });

const freshDeviceID = crypto.randomUUID();

export const appVersion = '0.6';
export const targetShowId = '4397438d-baad-453e-9127-382bd1219a0b';

const App: React.FC = () => {
  const { deviceId, setDeviceId } = useVotingStateStore();

  useEffect(() => {
    if (deviceId === undefined || deviceId === null || deviceId === '') {
      setDeviceId(freshDeviceID);
    }

    // Event delegation: Add a single event listener to the document
    const handleClick = (event: any) => {
      const el = event.target;
      console.log('CLICK!');

      // Check if the clicked element is a button with the class 'wobble-animate'
      if (
        el.classList.contains('standard-btn') ||
        el.classList.contains('wobble-animate') ||
        el.classList.contains('standard-btn-secondary') ||
        el.classList.contains('standard-input')
      ) {
        if (!el.classList.contains('no-wobble')) el.classList.add('button-wobble');
      }
    };

    // Remove 'button-wobble' class after the animation ends
    const handleAnimationEnd = (event: any) => {
      event.target.classList.remove('button-wobble');
    };

    // Add event listeners
    document.addEventListener('click', handleClick);
    document.addEventListener('animationend', handleAnimationEnd);

    // Cleanup function to remove event listeners on unmount
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);

  useEffect(() => {
    if ('ontouchstart' in window) {
      // On first touch, lock the viewport size
      document.body.style.height = `${window.innerHeight}px`;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Clean up: revert any changes when the component is unmounted
      document.body.style.height = '';
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet animated={false} id="main-content">
          <Route exact path={ERoutes.BASE} component={LandingPage} />
          <Route render={() => <Redirect to={ERoutes.BASE} />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};
export default App;
