import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Store = {
  vote?: {
    roundContestantId: string;
    deviceId: string;
  };
  setVote: (votesCast: { roundContestantId: string; deviceId: string }) => void;
  isVoteCast: () => boolean;
  deviceId?: string;
  setDeviceId: (deviceId: string) => void;
};

export const useVotingStateStore = create<Store>()(
  persist(
    (set, getState) => ({
      deviceId: undefined,
      setDeviceId: (deviceId: string) => set((state) => ({ deviceId: deviceId })),
      vote: undefined,
      setVote: (votesCast: { roundContestantId: string; deviceId: string }) => set((state) => ({ vote: votesCast })),
      isVoteCast: () => !!getState().vote,
    }),
    {
      name: 'voting-state-storage',
    }
  )
);
