import React, { useEffect, useState, FC } from 'react';
import { castVote } from 'apis/general';
import { useVotingStateStore } from 'store/voting-state-store';

type VoteSlideCardProps = {
  className?: string;
  imageUrl?: string | null;
  name?: string | null;
  contestantId?: string | null;
};

const VoteSlideCard: FC<VoteSlideCardProps> = ({ className = '', imageUrl = '', name = '', contestantId = '' }) => {
  const [isOverlayEnabled, setIsOverlayEnabled] = useState<boolean>(false);
  const { setVote, isVoteCast, deviceId } = useVotingStateStore();
  const [isCastingVote, setIsCastingVote] = useState<boolean>(false);

  useEffect(() => {
    console.log('VoteSlideCard mounted.');
  }, []);

  const handleCardClick = () => {
    console.log('Card itself was clicked');
    setIsOverlayEnabled(true);
  };

  const handleActionClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isAccept: boolean) => {
    event.stopPropagation();
    console.log('Action button clicked. Is accept: ' + isAccept);
    //event.target.element.classList.add('button-wobble');

    event.currentTarget.classList.add('button-wobble');
    if (!isAccept)
      setTimeout(() => {
        setIsOverlayEnabled(false);
      }, 200);
    else {
      console.log(!contestantId, !deviceId, isVoteCast());
      if (!contestantId || !deviceId || isVoteCast()) return;
      setIsCastingVote(true);
      setTimeout(async () => {
        await castVote(contestantId, deviceId);
        setVote({ roundContestantId: contestantId, deviceId: deviceId });
      }, 200);
    }
  };

  return (
    <div className="aspect-square bg-cover relative" onClick={handleCardClick} style={{ backgroundImage: `url('${imageUrl}')` }}>
      <div className="w-full absolute left-0 right-0 top-0 h-[35px] bg-neutral-900/70 flex flex-row items-center justify-center z-20">
        <p className="text-neutral-50 italic text-xs  tracking-wider">{isOverlayEnabled ? 'Du har 1 stemme tilbage.' : 'Klik på billedet for at stemme'}</p>
      </div>

      {!isOverlayEnabled && (
        <div className="w-full absolute left-0 right-0 bottom-0 h-[35px] bg-neutral-900/70 flex flex-row items-center justify-center">
          <p className="text-neutral-50 text-lg tracking-wide">{name}</p>
        </div>
      )}

      {isOverlayEnabled && (
        <div className="overlay w-full h-full absolute bg-neutral-900/70 flex flex-col justify-center items-center z-10">
          <p className="text-neutral-50">Er du sikker på du vil stemme på</p>
          <h3>{name}?</h3>
          <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
            <button
              onClick={(e) => handleActionClick(e, false)}
              className="w-16 h-16 wobble-animate rounded-full shadow-xl drop-shadow-xl bg-red-500 flex flex-col justify-center items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
            <button
              disabled={isCastingVote}
              onClick={(e) => handleActionClick(e, true)}
              className="w-24 h-24 wobble-animate rounded-full shadow-xl drop-shadow-xl bg-success flex flex-col justify-center items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-16">
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VoteSlideCard;
