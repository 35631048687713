import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { appVersion, targetShowId } from 'App';

import logoImg from 'static/assets/img/logo_compressed.webp';
import bgStars from 'static/assets/img/fullscreen/background_turned_compressed.png';
import { ActiveShowStructure, fetchShowStatus } from 'apis/general';
import { useVotingStateStore } from '../../store/voting-state-store';
import { Swiper, SwiperSlide } from 'swiper/react';
import VoteSlideCard from '../components/cards/VoteSlideCard';

const LandingPage: React.FC = () => {
  const [showStructure, setShowStructure] = useState<ActiveShowStructure | null>(null);
  const { isVoteCast } = useVotingStateStore();
  const [activeSliderIndex, setActiveSliderIndex] = useState(0);

  useEffect(() => {
    void getShowStructure();

    const interval = setInterval(() => getShowStructure(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getShowStructure = async () => {
    const data = await fetchShowStatus(targetShowId);
    console.log('Refreshing data..');
    setShowStructure(data);
  };

  const getShowStatus = (): 'open' | 'closed' | 'not-opened' => {
    if (showStructure?.active_voting_round?.opened_at === null && showStructure?.active_voting_round?.closed_at === null) return 'not-opened';
    if (showStructure?.active_voting_round?.opened_at !== null && showStructure?.active_voting_round?.closed_at === null) return 'open';
    if (showStructure?.active_voting_round?.opened_at !== null && showStructure?.active_voting_round?.closed_at !== null) return 'closed';
    return 'not-opened';
  };

  const isUserVotingInProgress = getShowStatus() === 'open' && !isVoteCast();

  return (
    <IonPage>
      <IonContent>
        {/*<span className="absolute z-10 bottom-1 left-1 w-full text-center text-primary-color">{appVersion}</span>*/}

        <img src={bgStars} alt="bg" className="absolute z-[-1] top-[50%] left-[50%] min-w-full min-h-full object-cover translate-x-[-50%] translate-y-[-50%]" />

        <div className="flex flex-col justify-center items-center h-full">
          <img src={logoImg} height={200} alt="Knæk Cancer" className={isUserVotingInProgress ? 'h-[12%] mb-2' : 'h-[20%]'} />

          {(getShowStatus() === 'not-opened' || getShowStatus() === 'closed') && !isVoteCast() && (
            <div className="w-full p-theme-spacing text-center mb-[30%]">
              <h3 className="uppercase">AFSTEMNING ER IKKE ÅBEN</h3>
              <p>Når afstemningen åbner, kan du stemme her.</p>
            </div>
          )}

          {isVoteCast() && (
            <div className="w-full p-theme-spacing text-center mb-[30%]">
              <h3 className="uppercase">TAK FOR DIN STEMME</h3>
              <p>Læg venligst telefonen tilbage i lommen.</p>
            </div>
          )}

          {isUserVotingInProgress && (
            <div className="w-full p-0 text-center">
              <h3 className="uppercase">AFGIV DIN STEMME</h3>
              <p>Du kan kun afgive én stemme.</p>

              <div className="w-full mt-4 mb-3">
                <Swiper spaceBetween={50} slidesPerView={1} onSlideChange={(swiper) => setActiveSliderIndex(swiper.activeIndex)} onSwiper={(swiper) => console.log(swiper)}>
                  {showStructure?.contestants?.map((contestant) => (
                    <SwiperSlide key={contestant.roundContestantId}>
                      <VoteSlideCard key={contestant.roundContestantId} contestantId={contestant.roundContestantId} imageUrl={contestant.image_url} name={contestant.name} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="w-full flex flex-row justify-center items-center gap-1">
                {showStructure?.contestants?.map((c, i) => (
                  <div
                    className={`${activeSliderIndex === i ? 'bg-neutral-300' : 'bg-transparent'} border border-neutral-300 h-2 w-2 rounded-full`}
                    key={`dot-${c.roundContestantId}`}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage;
