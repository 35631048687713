import { createClient } from '@supabase/supabase-js';
import type { Database } from 'types/database.types';

export const supabaseUrl = 'https://bsioytnkawdrkeruolur.supabase.co';
export const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzaW95dG5rYXdkcmtlcnVvbHVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg0NjgxMjcsImV4cCI6MjAzNDA0NDEyN30.P_5K94_CyMRfwBN_W5RD0gLhETfW7YfvZxVCyWVxhZo';

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

// Gives the type of single elements from an array
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

// Typed Usage Example
/*
type Profiles = Awaited<ReturnType<typeof fetchProfile>>['data'];
type Profile = ArrayElement<Profiles>;
const fetchProfile = async () => await supabase.from('profile').select('*');
 */

/** Helper utility for checking timings and repeats of async calls */
export const isDebugging = true;
export const getSeed = () => Math.floor(Math.random() * 1000);

export const startTimerLog = (name: string): number => {
  if (!isDebugging) return 0;

  const seed = getSeed();
  console.log(`${name} Started ${seed}`);
  console.time(`✔️ ${name} Ended ${seed}`);

  return seed;
};

export const stopTimerLog = (name: string, seed: number) => {
  if (isDebugging) console.timeEnd(`✔️ ${name} Ended ${seed}`);
};
