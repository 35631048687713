import { supabase } from './supabaseClient';
import { compareAsc, parseISO } from 'date-fns';

export type ActiveShowStructure = Awaited<ReturnType<typeof fetchShowStatus>>;
export const fetchShowStatus = async (showId: string) => {
  const { data, error } = await supabase
    .from('show')
    .select(
      '*, active_season:season!FK_70978840204a147a583748fa944(*, active_event:event!FK_7c62ca674d4966f936d5d390ff1(*, active_voting_round:voting_round!FK_3fdf4e9574ea783728d0dca32f8(*, round_contestant(roundContestantId:id, created_at, contestant(name, image_url)))))'
    )
    .eq('id', showId)
    .single();

  if (error) {
    console.error('Error fetching show status:', error);
    return null;
  }

  return {
    id: data.id,
    title: data.title,
    active_season: data.active_season,
    active_event: data.active_season?.active_event,
    active_voting_round: data.active_season?.active_event?.active_voting_round,
    contestants: data.active_season?.active_event?.active_voting_round?.round_contestant
      .map((rc) => ({ roundContestantId: rc.roundContestantId, ...rc.contestant, created_at: rc.created_at }))
      .sort((a, b) => compareAsc(parseISO(a.created_at), parseISO(b.created_at))),
  };
};

export const castVote = async (roundContestantId: string, deviceId: string) => {
  try {
    const { error } = await supabase.from('vote').insert([{ device_id: deviceId, roundContestantId }]);

    if (error) {
      console.error('Error inserting vote:', error.message);
    } else {
      console.log('Vote inserted:', deviceId, roundContestantId);
    }
  } catch (error) {
    console.error('Error posting vote:', error);
  }
};
